import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from 'react-icons/bs';
import { AiOutlinePhone } from 'react-icons/ai';
import { AiOutlineMail } from 'react-icons/ai';
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState('Submit');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm("service_bdfl0ie", "template_sqw247g", form.current, 'CLcHWAKSemVMd1_sU')
            .then((result) => {
                console.log(result.text);
                setButtonText('Message Sent');
                setTimeout(() => {
                    setButtonText('Submit'); // Reset button text after 3 seconds
                }, 3000);
            }, (error) => {
                console.log(error.text);
                setButtonText('Failed to Send');
                setTimeout(() => {
                    setButtonText('Submit'); // Reset button text after 3 seconds
                }, 3000);
            });

        e.target.reset();
    };

    return (
        <section className="contact-container container">
                <div className="contact-cards-container">
                    <div className="get_form_inner2">
                        <h2>CALL US</h2>
                        <p><AiOutlinePhone /><a href="tel:3234439913"> +1 (323) 443-9913</a></p>
                    </div>

                    <div className="get_form_inner2">
                        <h2>EMAIL</h2>
                        <p><AiOutlineMail /> info@33rdstreetdetox.com</p><br />
                    </div>

                    <div className="get_form_inner2">
                        <h2>LOCATION</h2>
                        <p><BsFillPinMapFill /> 1018 33rd St, Los Angeles CA 90011</p>
                    </div>
                </div>

            <div className="get_form_inner">
                <div className="get_form_inner_text">
                    <h3>Get In Touch</h3>
                    <p><i>We'll keep your contact information strictly confidential.</i></p><br />
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <div className="inputs">
                        <input 
                            type="text" 
                            name='name' 
                            placeholder='Your Name' 
                            required 
                            pattern="[A-Za-z\s]+" 
                            title="Name should contain only letters and spaces." 
                        /> <br />
                        <input 
                            type="email" 
                            name='email' 
                            placeholder='Your Email' 
                            required 
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                            title="Please enter a valid email address." 
                        /> <br />
                        <input 
                            type="tel" 
                            name='phone' 
                            placeholder='Your Phone' 
                            required 
                            pattern="[0-9\s\-\+\(\)]+"
                            title="Phone number should contain only numbers and optional characters like spaces, dashes, or parentheses." 
                        /> <br />
                        <input 
                            type="text" 
                            name='subject' 
                            placeholder='Subject' 
                            required 
                            pattern=".{3,}" 
                            title="Subject should be at least 3 characters long." 
                        /> <br />
                    </div>
                    <div>
                        <textarea 
                            name="message" 
                            placeholder="How can we help?" 
                            cols="50" 
                            rows="8" 
                            required 
                            pattern=".{10,}"
                            title="Message should be at least 10 characters long."
                        ></textarea>

                    <div className="disclaimer">
                      <p>33rd Street Detox needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our  
                      <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                          <Link to="/privacy-policy"><span> Privacy Policy </span></Link>
                      </LinkRoll></p>
                    </div>


                        <input type="submit" value={buttonText} />
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ContactForm;
